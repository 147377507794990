/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        //select language on header
        if ($("#lang_choice_1").length > 0) {
          $("#lang_choice_1").select2({
            minimumResultsForSearch: -1,
            dropdownParent: $("div.language-wrapper"),
          });

          $("#lang_choice_1").change(function(e) {
            window.location = e.currentTarget.value;
          });
        }

        /*MENU MOBILE*/
        $(".burger-container").on("click", function (e) {
          $("header .header").toggleClass("menu-opened");
          //$('#menu-full').fadeToggle(200);
          $("#menu-full").toggleClass("opened");
          $(".menu-bg").toggleClass("opened");
          $("body").toggleClass("over");
          $("html").toggleClass("over");
        });

        /*SEARCH MOBILE*/
        $("#showSearch").click(function () {
          Search();
          $("#searchform-wrap #search").focus();
        });
        $("#hideSearch").click(function () {
          Search();
          console.log("ola");
        });

        function Search() {
          $("#searchform-wrap").toggleClass("active");
        }

        // page preloader
        if ($(".preloader").length > 0) {
          $(window).load(function () {
            setTimeout(function () {
              $(".preloader").fadeOut("slow");
            }, 500);
          });
          setTimeout(function () {
            $(".preloader").fadeOut("slow");
          }, 1500);
        }
      },
    },
    // Home page
    page_template_pg_homepage: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS

        var swiperBanner = new Swiper(".banner .swiper-container", {
          loop: false,
          slidesPerView: 1,
          watchOverflow: true,
          pagination: {
            el: ".banner .swiper-container .swiper-pagination",
            type: "bullets",
            clickable: true,
          },
          autoplay: {
            delay: 10000,
          },
        });
        var swiperPArceiros = new Swiper(".parceiros .swiper-container", {
          loop: false, // <- causes issues combined with multi row setup!!
          slidesPerView: 2,
          slidesPerColumn: 2,
          spaceBetween: 0,
          pagination: {
            el: ".parceiros .swiper-container .swiper-pagination",
            type: "bullets",
            dynamicBullets: true,
            clickable: true,
          },

          autoplay: {
            delay: 3500,
          },

          breakpoints: {
            440: {
              slidesPerView: 3,
              slidesPerColumn: 2,
            },
            768: {
              slidesPerView: 5,
              slidesPerColumn: 2,
            },
            1024: {
              slidesPerView: 7,
              slidesPerColumn: 2,
            },
          },
        });
      },
    },
    // About us page, note the change from about-us to about_us.
    page_template_pg_sobre_nos: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
        var mySwiper = new Swiper(".about-galeria .swiper-container", {
          // Optional parameters
          loop: true,
          pagination: {
            el: ".about-galeria .swiper-pagination",
            type: "bullets",
            clickable: true,
          },

          // Navigation arrows
          /* navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          } */
        });
      },
    },
    // About us page, note the change from about-us to about_us.
    page_template_pg_servicos: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
        var mySwiper = new Swiper(".servicos-portfolio.one .swiper-container", {
          // Optional parameters
          slidesPerView: "1.35",
          //direction: 'vertical',
          //allowTouchMove: false,
          // Navigation arrows
          // navigation: {
          //   nextEl: '.swiper-button-next',
          //   prevEl: '.swiper-button-prev',
          // },
          pagination: {
            el: ".servicos-portfolio.one .swiper-container .swiper-pagination",
            type: "bullets",
            dynamicBullets: true,
            clickable: true,
          },

          breakpoints: {
            768: {
              allowTouchMove: true,
              slidesPerView: "auto",
              //direction: 'horizontal',
            },
          },
          on: {
            slideChange: function () {
              $(".servicos-portfolio.one .swiper-wrapper").removeClass(
                "not-move"
              );
            },
          },
        });
        var mySwiper2 = new Swiper(
          ".servicos-portfolio.two .swiper-container",
          {
            // Optional parameters
            slidesPerView: "1.35",
            // direction: 'vertical',
            // allowTouchMove: false,
            // Navigation arrows
            // navigation: {
            //   nextEl: '.swiper-button-next',
            //   prevEl: '.swiper-button-prev',
            // },
            pagination: {
              el: ".servicos-portfolio.two .swiper-container .swiper-pagination",
              type: "bullets",
              dynamicBullets: true,
              clickable: true,
            },

            breakpoints: {
              768: {
                allowTouchMove: true,
                //direction: 'horizontal',
                slidesPerView: "auto",
              },
            },
            on: {
              slideChange: function () {
                $(".servicos-portfolio.two .swiper-wrapper").removeClass(
                  "not-move"
                );
              },
            },
          }
        );

        /* $(window).load(function() {
          var tam = $(".portfolio .swiper-slide").height();
          tam = tam - $(".portfolio .swiper-slide figcaption").height();
          //console.log(tam);
          $(".swiper-button-prev").css("top", tam/2 ); 
          $(".swiper-button-next").css("top", tam/2 );     
        });   */

        //select Service on top // MOBILE
        if ($("#sel-tab").length > 0) {
          $("#sel-tab").select2({
            minimumResultsForSearch: -1,
            dropdownParent: $("section.servicos-topo"),
          });
        }
        $("#sel-tab").on("select2:selecting", function (e) {
          $("#" + e.params.args.data.id).click();
        });

        //change URL Function
        function ChangeUrl(page, url) {
          if (typeof history.pushState != "undefined") {
            var obj = { Page: page, Url: url };
            history.pushState(obj, obj.Page, obj.Url);
          } else {
            alert("Browser does not support HTML5.");
          }
        }

        //GET SERVICE ON DEKTOP
        var url = document.location.href;
        var id = url.substring(url.lastIndexOf("#") + 1);
        $(".tabs input[type='radio']").change(function () {
          console.log(this.getAttribute("id"));
          ChangeUrl("", "#" + this.getAttribute("id"));
        });
      },
    },
    single_product: {
      init: function () {
        // insere label quantidade antes do campo
        var label = "Quantidade";
        if (lang == "en") label = "Quantity";
        $("<label>" + label + "</label>").insertBefore(
          $("div.quantity input[class='minus']")
        );
      },
      finalize: function () {
        $(".custom-variations li").click(function () {
          var value = $(this).data("value");
          var id = $(this).parent().data("id");

          if (value) {
            $("select#" + id)
              .val(value)
              .trigger("change");

            $(".custom-variations li").removeClass("active");
            $(this).addClass("active");
          }
        });

        $("#pa_tamanhos").select2({
          minimumResultsForSearch: -1,
          dropdownParent: $("div.variations"),
        });

        /*$(".wpgis-slider-nav li.slick-slide:eq(0)").hide();
        $('.wpgis-slider-nav li.slick-slide:eq(0) img').on("load", function() {  
          console.log($(this).attr("src"));
        });*/
      },
    },
    archive: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS

        listagem_produtos();

        //Filtros mobile
        $("#filt-btn").on("click", function () {
          $(".products-list .filters .filter-content.filt").slideToggle();
          $("#filt-btn").toggleClass("active");

          $(".products-list .filters .filter-content.catg").slideUp(); //remove o outro se tiver ativo
          $("#catg-btn").removeClass("active");
        });
        $("#catg-btn").on("click", function () {
          $(".products-list .filters .filter-content.catg").slideToggle();
          $("#catg-btn").toggleClass("active");

          $(".products-list .filters .filter-content.filt").slideUp(); //remove o outro se tiver ativo
          $("#filt-btn").removeClass("active");
        });
      },
    },
    page_template_default: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS

        listagem_produtos();
      },
    },
    minha_conta: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS

        //select Service on top
        if ($("#sel-tab").length > 0) {
          $("#sel-tab").select2({
            minimumResultsForSearch: -1,
            dropdownParent: $("div.woocommerce"),
          });
        }
        $("#sel-tab").on("select2:selecting", function (e) {
          window.location = e.params.args.data.id;
        });

        var url = location.href;
        if (url.indexOf("?e=") !== -1) {
          $("html, body").animate(
            { scrollTop: $("#div_register").offset().top },
            "fast"
          );
        }
      },
    },
    page_template_pg_checkout: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
        if ($("select.country_to_state").length > 0) {
          $("select.country_to_state").select2({
            minimumResultsForSearch: -1,
            dropdownParent: $("div.woocommerce"),
          });
        }
      },
    },
    page_template_pg_private: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {
        var privateSwiperBanner = new Swiper(".banner .swiper-container", {
          loop: false,
          slidesPerView: 1,
          watchOverflow: true,
          pagination: {
            el: ".banner .swiper-container .swiper-pagination",
            type: "bullets",
            clickable: true,
          },
          autoplay: {
            delay: 10000,
          },
        });
        // Add class on scroll
        $(document).ready(function () {
          $(window).scroll(function () {
            if ($(this).scrollTop() > 10) {
              $(".header").addClass("scrolled");
            } else {
              $(".header").removeClass("scrolled");
            }
          });
        });
      },
    },
  };
  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  var filtra = 1;
  function filterClick() {
    var transition = "fade";
    var speed = 250;
    var data = null;

    var data_taxonomy = "";
    var data_taxonomy_terms = "";
    var data_taxonomy_terms_cores = "";
    var data_taxonomy_terms_tamanhos = "";
    var data_taxonomy_terms_generos = "";
    var data_taxonomy_operator = "";

    if ($("span.filter-option").length > 0) {
      $("span.filter-option").each(function () {
        var taxonomy = $(this).data("taxonomy");

        if ($(this).hasClass("active")) {
          if (taxonomy == "product_cat") {
            // categorias
            if (!data_taxonomy_terms)
              data_taxonomy_terms = $(this).data("taxonomy-terms");
            else {
              if (data_taxonomy.indexOf("product_cat") === -1) {
                if (
                  data_taxonomy_terms.indexOf(
                    $(this).data("taxonomy-terms")
                  ) === -1
                ) {
                  data_taxonomy_terms += ":" + $(this).data("taxonomy-terms");
                }
              } else {
                if (
                  data_taxonomy_terms.indexOf(
                    $(this).data("taxonomy-terms")
                  ) === -1
                ) {
                  data_taxonomy_terms += "," + $(this).data("taxonomy-terms");
                }
              }
            }
            if (data_taxonomy.indexOf("product_cat") === -1) {
              if (!data_taxonomy) data_taxonomy = taxonomy;
              else data_taxonomy += ":" + taxonomy;

              if (!data_taxonomy_operator) data_taxonomy_operator = "IN";
              else data_taxonomy_operator += ":IN";
            }
          } else if (taxonomy == "tipo") {
            if (!data_taxonomy_terms)
              data_taxonomy_terms = $(this).data("taxonomy-terms");
            else {
              if (data_taxonomy.indexOf("tipo") === -1) {
                if (
                  data_taxonomy_terms.indexOf(
                    $(this).data("taxonomy-terms")
                  ) === -1
                ) {
                  data_taxonomy_terms += ":" + $(this).data("taxonomy-terms");
                }
              } else {
                if (
                  data_taxonomy_terms.indexOf(
                    $(this).data("taxonomy-terms")
                  ) === -1
                ) {
                  data_taxonomy_terms += "," + $(this).data("taxonomy-terms");
                }
              }
            }
            if (data_taxonomy.indexOf("tipo") === -1) {
              if (!data_taxonomy) data_taxonomy = taxonomy;
              else data_taxonomy += ":" + taxonomy;

              if (!data_taxonomy_operator) data_taxonomy_operator = "IN";
              else data_taxonomy_operator += ":IN";
            }
          }
        }
      });
    }

    if ($("select.dropdown").length > 0) {
      $("select.dropdown").each(function () {
        var taxonomy = $($(this).select2("data")[0].element).data("taxonomy");

        if (taxonomy == "pa_cores") {
          // cores
          data_taxonomy += ":" + taxonomy;
          data_taxonomy_operator += ":IN";

          data_taxonomy_terms_cores = $(
            $(this).select2("data")[0].element
          ).data("taxonomy-terms");
        }
        if (taxonomy == "pa_tamanhos") {
          // tamanhos
          data_taxonomy += ":" + taxonomy;
          data_taxonomy_operator += ":IN";

          data_taxonomy_terms_tamanhos = $(
            $(this).select2("data")[0].element
          ).data("taxonomy-terms");
        }
        if (taxonomy == "genero") {
          // generos
          data_taxonomy += ":" + taxonomy;
          data_taxonomy_operator += ":IN";

          data_taxonomy_terms_generos = $(
            $(this).select2("data")[0].element
          ).data("taxonomy-terms");
        }
      });
    }

    if (data_taxonomy_terms_cores) {
      data_taxonomy_terms += ":" + data_taxonomy_terms_cores;
    }
    if (data_taxonomy_terms_tamanhos) {
      data_taxonomy_terms += ":" + data_taxonomy_terms_tamanhos;
    }
    if (data_taxonomy_terms_generos) {
      data_taxonomy_terms += ":" + data_taxonomy_terms_generos;
    }

    // se não tiver nada selecionado pega no que vem da página atual
    if (!data_taxonomy) {
      console.log("por defeito!!!");
      data_taxonomy = $("#current_taxonomy").data("taxonomy");
      data_taxonomy_terms = $("#current_taxonomy").data("taxonomy-terms");
      data_taxonomy_operator = $("#current_taxonomy").data("taxonomy-operator");
    }

    data = {
      taxonomy: data_taxonomy,
      taxonomyTerms: data_taxonomy_terms,
      taxonomyOperator: data_taxonomy_operator,
    };

    // mostra ou esconde botão de limpar os filtros, só mostra se não tiver termos de pesquisa
    // e não são iguais ao da página atual (somente se estiver na página de uma categoria)
    if (
      data_taxonomy_terms != "" &&
      data_taxonomy_terms != $("#current_taxonomy").data("taxonomy-terms")
    ) {
      $(".filters .clean").fadeIn();
    } else {
      filtra = 1;
      $(".filters .clean").fadeOut();
    }

    // retira evento click dos filtros enquanto atualiza
    $("span.filter-option").each(function () {
      $(this).css("pointer-events", "none");
    });

    // Call Ajax Load More `filter` method
    ajaxloadmore.filter(transition, speed, data);
  }

  function listagem_produtos() {
    // slider banners
    var total_slides = $(".banners .swiper-container").data("total");
    var loop = false;
    if (total_slides > 3) {
      loop = true;
    }
    var slider = new Swiper(".banners .swiper-container", {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: loop,
      watchOverflow: true,
      pagination: {
        el: ".banners .swiper-pagination",
        clickable: true,
      },
    });

    var current_language = $("#current_language").val();
    window.almComplete = function (alm) {
      //console.log(alm.ajax.dataset);
      var totalposts = alm.totalposts;

      // repõe evento click dos filtros quando termina
      $("span.filter-option").each(function () {
        $(this).css("pointer-events", "auto");
      });
    };

    if ($(".filters").length > 0) {
      $("span.filter-option").click(function () {
        $(this).toggleClass("active");
        filterClick();
      });

      $("select.dropdown").select2({
        minimumResultsForSearch: -1,
        dropdownParent: $(".products-list .filters"),
      });

      $("select.dropdown").change(function () {
        if (filtra === 1) filterClick();
      });

      // remove todos os filtros
      $(document).on("click", ".filters .clean", function () {
        $("span.filter-option").each(function () {
          // só remove se não estiver na página de categoria e a selecionada é esta
          if (
            $(this).data("taxonomy-terms") !=
            $("#current_taxonomy").data("taxonomy-terms")
          ) {
            $(this).removeClass("active");
          }
        });

        filtra = 0;
        $("select.dropdown").each(function () {
          $(this).val("").trigger("change");
        });

        filterClick();
      });
      //filterClick();
    }
  }
})(jQuery); // Fully reference jQuery after this point.
